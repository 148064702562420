import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as AirPodsPro } from './../assets/AirPodsPro.svg';
import { ReactComponent as AppleWatchUltra } from './../assets/AppleWatchUltra.svg';
import { ReactComponent as IPad } from './../assets/iPad.svg';
import { ReactComponent as IPhone13 } from './../assets/iPhone13.svg';
import { ReactComponent as IPhone14Pro } from './../assets/iPhone14Pro.svg';
import { ReactComponent as MacBook } from './../assets/MacBook.svg';
import { ReactComponent as MacStudio } from './../assets/MacStudio.svg';
import { ReactComponent as MacMini } from './../assets/MacMini.svg';
import { ReactComponent as HomePod } from './../assets/HomePod.svg';
import { ReactComponent as StudioDisplay } from './../assets/StudioDisplay.svg';
import { ReactComponent as USBCtoApplePencilAdapter } from './../assets/USBCtoApplePencilAdapter.svg';

const PaddedSpinner: React.FC = () => {
    return (
        <div style={{ height: 44, width: 44 }}>
            <Spinner />
        </div>
    )
}

export default function imageForProduct(productName: string | null | undefined) {
    switch (productName) {
        case "AirPodsProGen2":
        case "AirPodsMax20_1": // TODO: need a proper icon here
            return AirPodsPro
        case "AppleWatchUltra":
        case "AppleWatchUltra2":
            return AppleWatchUltra
        case "iPadMiniWifi":
        case "iPadMiniCellular":
        case "iPadAir11thGen_11in_Wifi":
        case "iPadAir11thGen_11in_Cellular":
        case "iPadAir11thGen_13in_Wifi":
        case "iPadAir11thGen_13in_Cellular":
        case "iPadProM4_11in_Wifi":
        case "iPadProM4_11in_Cellular":
        case "iPadProM4_13in_Wifi":
        case "iPadProM4_13in_Cellular":
            return IPad
        case "iPhoneRegular13":
        case "iPhoneMini13":
            return IPhone13
        case "iPhoneRegular14":
        case "iPhonePlus14":
        case "iPhonePro14":
        case "iPhoneProMax14":
        case "iPhoneRegular15":
        case "iPhonePlus15":
        case "iPhonePro15":
        case "iPhoneProMax15":

        case "iPhone16_128":
        case "iPhone16_256":
        case "iPhone16_512":
        case "iPhone16Plus_128":
        case "iPhone16Plus_256":
        case "iPhone16Plus_512":
        case "iPhone16Pro_128":
        case "iPhone16Pro_256":
        case "iPhone16Pro_512":
        case "iPhone16Pro_1TB":
        case "iPhone16ProMax_256":
        case "iPhone16ProMax_512":
        case "iPhone16ProMax_1TB":
            return IPhone14Pro
        case "MacBookPro":
        case "MacBookPro23_1":
        case "MacBookPro_14_23_2":
        case "MacBookPro_16_23_2":
        case "M2MacBookPro13":
        case "M2MacBookAir":
        case "MacBookAir_13_24_1":
        case "MacBookAir_15_24_1":
            return MacBook
        case "MacStudio":
        case "MacStudio23_1":
            return MacStudio
        case "MacMini23_1":
            return MacMini
        case "StudioDisplay":
            return StudioDisplay
        case "ApplePencilUSBCAdapter":
            return USBCtoApplePencilAdapter
        case "HomePod_2ndGen":
            return HomePod
        default:
            // TODO: better loading state would be good here - this flashes when it loads quickly 
            return PaddedSpinner
    }
}

/**
 * 
 





















 */
